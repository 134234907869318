import React, { useState } from "react";
import { Row, Col, Form, Container, Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, find, isUndefined, filter, sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";
import Select from "react-select";
import MaterialTable from "material-table";
import Swal from "sweetalert";
import {
  addCashPayment,
  submitBankRecipet,
  deleteBankRecipet,
  resetFunction3,
} from "./Voucher.action";
import moment from "moment";
import { previewVoucher, mergeNames } from "../../../../global/globalFunctions";

const CashPayment = (props) => {
  const [accountNo, setaccountNo] = useState();
  const [cashAccountTitle, setCashAccountTitle] = useState();
  const [voucher, setVoucher] = useState();
  const [level4Id, setLevel4Id] = useState(2);
  const [datestate, setDatestate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [level4Label, setLevel4Lablel] = useState(`3-1-1-2/BANKS`);
  const [trackingNo, setTrackingNo] = useState("");
  const [destination, setDestination] = useState("");
  const [packages, setPackages] = useState("");
  const [weight, setWeight] = useState(0);
  const [weightCharges, setWeightCharges] = useState(0);
  const [rateFirstKg, setRateFirstKg] = useState(0);
  const [rateAddKg, setRateAddKg] = useState(0);
  const [rateFlat, setRateFlat] = useState(0);
  const [service, setService] = useState("");
  const handleVisible = (data) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: `Voucher "${data.voucherManualId}"Added successfully...`,
      icon: "success",
      buttons: ["Cancel", "Print Voucher"],
    }).then((willPrint) => {
      if (willPrint) {
        //subPage()
        previewVoucher(data, "Pending");
      } else {
        // setShowDialog(false)
      }
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  const [values, setValues] = useState({
    voucherDescription: "",
    voucherAmount: "",
    PONo: "",
    billNo: "",
    accountTittle: "",
    // payTo: "",
    levelFiveId: "",
    voucherDate: "",
    accountType: "1",
    credit: "",
    debit: "",
    crossAccount: "",
  });

  const inputEventAccount = (selectedOption, type) => {
    setValues({
      levelFiveId: selectedOption.value,
    });
  };

  const inputEvent1Account = (selectedOption) => {
    setValues({
      ...values,
      ["levelFiveId"]: selectedOption.value,
      ["accountTittle"]:
        get(
          find(
            props.level5List,
            (x) => x.levelfiveData.levelFiveId == selectedOption.value
          ),
          "levelfiveData.allLevelKey",
          0
        ) +
        " / " +
        get(
          find(
            props.level5List,
            (x) => x.levelfiveData.levelFiveId == selectedOption.value
          ),
          "levelfiveData.levelFiveName",
          0
        ),
    });
  };

  // const inputEvent = (event) => {
  //   event.target.name == "voucherDate" &&
  //     setDatestate(event.target.value);
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   })
  // };
  const inputEvent = (event) => {
    event.target.name == "voucherDate" && setDatestate(event.target.value);
    if (event.target.name == "voucherAmount") {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
        credit: Number(event.target.value),
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  };

  const cashAccountNoTop =
    !isEmpty(props.level5List) &&
    props.level5List
      .filter((x) => x.levelfiveData.levelFourId == level4Id)
      .map((y) => {
        let data = {
          value: y.levelfiveData.levelFiveId,
          label:
            y.levelfiveData.allLevelKey + " / " + y.levelfiveData.levelFiveName,
        };
        return data;
      });
  const getLevelFourList =
    !isEmpty(props.level4List) &&
    props.level4List.map((x) => {
      let data = {
        value: x.levelFourData.levelFourId,
        label:
          x.levelFourData.allLevlKey + " / " + x.levelFourData.levelFourName,
      };
      return data;
    });
  const handleChangeLevel4 = (selectedOption) => {
    setLevel4Id(selectedOption.value);
    setLevel4Lablel(selectedOption.label);
  };

  const cash = props.bankRecipet;
  const addPayment = () => {
    let newVal = values;
    if (!isEmpty(datestate)) newVal.voucherDate = datestate;
    props.submitBankRecipet(newVal);
    setValues({
      voucherDescription: values.voucherDescription,
      voucherAmount: "",
      // PONo: "",
      billNo: values.billNo,
      // accountTittle: "",
      // // payTo: "",
      // levelFiveId: "",
      // voucherDate: "",
      accountType: "1",
      // credit:"",
      // debit:"",
      // crossAccount: "",
      trackingNo: values.trackingNo,
      destination: values.destination,
      packages: values.packages,
      weight: values.weight,
      weightCharges: values.weightCharges,
      rateFirstKg: values.rateFirstKg,
      rateAddKg: values.rateAddKg,
      rateFlat: values.rateFlat,
      service: values.service,
    });
  };
  const cashAccountNo =
    !isEmpty(props.level5List) &&
    props.level5List.map((x) => {
      let data = {
        value: x.levelfiveData.levelFiveId,
        label:
          x.levelfiveData.allLevelKey + " / " + x.levelfiveData.levelFiveName,
      };
      return data;
    });

  const handleChangeAccountNo = (selectedOption) => {
    setaccountNo(selectedOption.value);
    setCashAccountTitle(
      `${
        find(
          props.level5List,
          (x) => x.levelfiveData.levelFiveId == selectedOption.value
        ).levelfiveData.allLevelKey
      } / ${
        find(
          props.level5List,
          (x) => x.levelfiveData.levelFiveId == selectedOption.value
        ).levelfiveData.levelFiveName
      }`
    );
  };

  const add = () => {
    let dt = cash.map((x) => {
      return x.datarecipet;
    });
    let cashData = [
      {
        voucherDescription: mergeNames(dt, "voucherDescription"),
        voucherAmount: sumBy(cash, (item) =>
          Number(item.datarecipet.voucherAmount)
        ),
        PONo: "",
        billNo: mergeNames(dt, "billNo"),
        trackingNo: mergeNames(dt, "trackingNo"),
        destination: mergeNames(dt, "destination"),
        packages: mergeNames(dt, "packages"),
        weight: mergeNames(dt, "weight"),
        weightCharges: mergeNames(dt, "weightCharges"),
        rateFirstKg: mergeNames(dt, "rateFirstKg"),
        rateAddKg: mergeNames(dt, "rateAddKg"),
        rateFlat: mergeNames(dt, "rateFlat"),
        service: mergeNames(dt, "service"),
        accountTittle: cashAccountTitle,
        levelFiveId: accountNo,
        voucherDate: datestate,
        accountType: "2",
        crossAccount: mergeNames(dt, "voucherDescription"),
        debit: sumBy(cash, (item) => Number(item.datarecipet.voucherAmount)),
        credit: "",
      },
    ];

    props.addCashPayment(
      {
        voucherTypeId: "4",
        levelFiveId: accountNo,
        cashAccTittle: cashAccountTitle,
        voucher: voucher,
        data: [...dt, ...cashData],
        voucherType: "BRV",
        credit_or_debit: "2",
      },
      handleVisible,
      handleDangerVisible
    );
    props.resetFunction3();
  };
  return (
    <>
      {props.isFetchinglevel5List || props.isFetchinglevel4List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>
              <Row>
                <Col lg="12">
                  <div className="text-white  ">
                    <Row>
                      <Col lg="4" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Date *</Form.Label>
                          <Form.Control
                            type="date"
                            name="voucherDate"
                            value={datestate}
                            //value={values.voucherDate}
                            max={moment(new Date()).format("YYYY-MM-DD")}
                            onChange={inputEvent}
                            disabled={!isEmpty(props.bankRecipet)}
                          />
                        </Form.Group>
                      </Col>

                      {/* <Col lg="4" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Select Level4</Form.Label> */}
                      {/* <Select
                            className="selectOptionColor"
                            value = {{label: level4Label}}
                            placeholder="Select Level 4..."
                            onChange={(e) => handleChangeLevel4(e)}
                            options={getLevelFourList}
                          /> */}
                      {/* <Form.Control
                            type="text"
                            name="selectlevel4"
                            value={level4Label}
                            //value={values.voucherDate}
                           
                            disabled={true}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col lg="6" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Bank A/C No *</Form.Label>
                          <Select
                            className="selectOptionColor"
                            value={{ label: cashAccountTitle }}
                            isDisabled={isUndefined(level4Id)}
                            placeholder="Select Cash Account No..."
                            onChange={handleChangeAccountNo}
                            options={filter(
                              cashAccountNoTop,
                              (x) => x.value !== values.levelFiveId
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="6" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label></Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Amount Type"
                            value="Debit"
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Account *</Form.Label>
                      <Select
                        value={{ label: values.accountTittle }}
                        placeholder="Select Cash Account No..."
                        onChange={(inputEventAccount, inputEvent1Account)}
                        options={filter(
                          cashAccountNo,
                          (x) => x.value !== accountNo
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Amount Type"
                        value="Credit"
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="2" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bill</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bill No"
                        name="billNo"
                        value={values.billNo}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="7" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        name="voucherDescription"
                        value={values.voucherDescription}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount *</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        name="voucherAmount"
                        value={values.voucherAmount}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Tracking No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Tracking No"
                        id="trackingNo"
                        name="trackingNo"
                        value={values.trackingNo}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Destination</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter destination"
                        id="destination"
                        name="destination"
                        value={values.destination}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Packages</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Packages"
                        id="packages"
                        name="packages"
                        value={values.packages}
                        // min={0}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Weight</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Weight"
                        id="weight"
                        name="weight"
                        value={values.weight}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Weight Charges</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Weight Charges"
                        id="weightCharges"
                        name="weightCharges"
                        value={values.weightCharges}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Rate First Kg</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Rate First Kg"
                        id="rateFirstKg"
                        name="rateFirstKg"
                        value={values.rateFirstKg}
                        // min={0}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Rate Add Kg</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Rate Add Kg"
                        id="rateAddKg"
                        name="rateAddKg"
                        value={values.rateAddKg}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Rate Flat</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Rate Flat"
                        id="rateFlat"
                        name="rateFlat"
                        value={values.rateFlat}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Service</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Service"
                        id="service"
                        name="service"
                        value={values.service}
                        // min={0}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="6" sm="12" className="text-center">
                  {isEmpty(values.voucherAmount) ||
                  isEmpty(values.accountTittle) ||
                  isEmpty(cashAccountTitle) ||
                  datestate > moment(new Date()).format("YYYY-MM-DD") ? (
                    <Button
                      className="sendButtonVoucher"
                      style={{ backgroundColor: "black" }}
                      disabled
                    >
                      ADD
                    </Button>
                  ) : (
                    <Button className="sendButtonVoucher" onClick={addPayment}>
                      ADD
                    </Button>
                  )}
                </Col>
                <Col lg="6" sm="12">
                  {isEmpty(cash) ? (
                    <Button
                      className="sendButtonVoucher"
                      style={{ backgroundColor: "black" }}
                      disabled
                    >
                      POST
                    </Button>
                  ) : (
                    <Button className="sendButtonVoucher" onClick={add}>
                      POST
                    </Button>
                  )}
                </Col>
              </Row>

              <div className="table">
                <MaterialTable
                  title={`VOUCHER TOTAL : ${sumBy(cash, (item) =>
                    Number(item.datarecipet.voucherAmount)
                  )}`}
                  columns={[
                    { title: "Date", field: "datarecipet.voucherDate" },
                    {
                      title: "Account Name",
                      field: "datarecipet.accountTittle",
                    },
                    { title: "Bill No", field: "datarecipet.billNo" },
                    {
                      title: "Description",
                      field: "datarecipet.voucherDescription",
                    },
                    { title: "Amount", field: "datarecipet.voucherAmount" },
                    {
                      title: "DR/CR",
                      field: "datarecipet.accountType",
                      render: (rowData) =>
                        rowData.datarecipet.accountType == 1
                          ? "Credit"
                          : rowData.datarecipet.accountType == 2
                          ? "Debit"
                          : "---",
                    },
                    { title: "Tracking No", field: "data.trackingNo" },
                    { title: "Destination", field: "data.destination" },
                    { title: "Packages", field: "data.packages" },
                    { title: "Weight", field: "data.weight" },
                    { title: "Weight Charges", field: "data.weightCharges" },
                    { title: "Rate First Kg", field: "data.rateFirstKg" },
                    { title: "Rate Add Kg", field: "data.rateAddKg" },
                    { title: "Rate Flat", field: "data.rateFlat" },
                    { title: "Service", field: "data.service" },
                  ]}
                  data={cash}
                  options={{
                    actionsColumnIndex: -1,
                    paging: true,
                    pageSize: 200, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    maxBodyHeight: "600px",
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteBankRecipet(oldData);
                        }, 600);
                      }),
                  }}
                />
              </div>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCashPayment: (data, handleVisible, handleDangerVisible) =>
      dispatch(addCashPayment(data, handleVisible, handleDangerVisible)),
    submitBankRecipet: (data) => dispatch(submitBankRecipet(data)),
    deleteBankRecipet: (id) => dispatch(deleteBankRecipet(id)),
    resetFunction3: () => dispatch(resetFunction3()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  bankRecipet: state.VoucherReducer.bankRecipet,
  level5List: state.accountsLevelReducer.level5List,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
});

export default connect(mapStateToProps, mapDispatchToProps)(CashPayment);
