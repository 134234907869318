import API from "../../../../global/api";
import { get, isEmpty } from "lodash";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";
import { sumBy } from "lodash";
import BalanceSheetDetail from "../BalanceSheet/BalanceSheetDetail";

export const requestAddAging = () => {
  return {
    type: "REQUEST_ADD_AGING",
  };
};
export const successAddAging = (data) => {
  return {
    type: "SUCCESS_ADD_AGING",
    payload: data,
  };
};
export const errorAddAging = () => {
  return {
    type: "ERROR_ADD_AGING",
  };
};

export const agingReport = (data, id, ACtitle) => {
  return (dispatch) => {
    dispatch(requestAddAging());
    API.post(`/aging/aging_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "jjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "AGING Report";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const code = `Code: ${id}`;
        const TitleOfAC = `Title Of A/C: ${ACtitle}`;
        const headers = [
          [
            "Last Paid Amount",
            "Last Paid On",
            "Balance",
            `    1-15\n${getData.duration[0].endDate}\n${getData.duration[0].startedDate}`,
            `    16-30\n${getData.duration[1].endDate}\n${getData.duration[1].startedDate}`,
            `    31-45\n${getData.duration[2].endDate}\n${getData.duration[2].startedDate}`,
            `    46-60\n${getData.duration[3].endDate}\n${getData.duration[3].startedDate}`,
            `    61-75\n${getData.duration[4].endDate}\n${getData.duration[4].startedDate}`,
            `    76-90\n${getData.duration[5].endDate}\n${getData.duration[5].startedDate}`,
            `   90-105\n${getData.duration[6].endDate}\n${getData.duration[6].startedDate}`,
            `   105-120\n${getData.duration[7].endDate}\n${getData.duration[7].startedDate}`,
          ],
        ];

        const data = [
          [
            getData.lastDebit,
            getData.lastDebitDate,
            "Balance",
            `${getData.ledger_history[0]}`,
            `${getData.ledger_history[1]}`,
            `${getData.ledger_history[2]}`,
            `${getData.ledger_history[3]}`,
            `${getData.ledger_history[4]}`,
            `${getData.ledger_history[5]}`,
            `${getData.ledger_history[6]}`,
            `${getData.ledger_history[7]}`,
          ],
        ];
        console.log(
          getData.ledger_history[0] +
          getData.ledger_history[1] +
          getData.ledger_history[2] +
          getData.ledger_history[3] +
          getData.ledger_history[4] +
          getData.ledger_history[5] +
          getData.ledger_history[6] +
          getData.ledger_history[7],
          "aaaaaaaaaaaaaaaaaaaaaa"
        );
        const grandTotal = `Grand Total: ${sumBy(getData.ledger_history)}`;

        let content = {
          startY: 160,
          startX: 0,
          head: headers,
          // tableWidth: "wrap",
          body: data,
        };
        doc.addImage(logo, "PNG", 660, 10, 150, 60);
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 290, 30, 0, 20);
        doc.setFontSize(14);
        // doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 220, 50, 0, 20);
        doc.text(companyNtn, 320, 70, 0, 20);
        doc.setFontSize(14);
        doc.setFont(undefined, "bold");
        doc.text(title, 390, 100);
        doc.setFontSize(12);
        doc.setFont(undefined, "normal");
        doc.text(code, 40, 120);
        doc.text(TitleOfAC, 40, 140);
        doc.autoTable(content);
        // doc.text(categoryTotal,570,doc.lastAutoTable.finalY + 20)
        doc.text(grandTotal, 670, doc.lastAutoTable.finalY + 40);
        // doc.save(`Aging Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
        doc.setProperties({
          title: `Aging Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`,
        });
        // var string = doc.output('datauristring','dddd');
        // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
        // var x = window.open('Demand Report');
        // x.document.open('Demand Report');
        // x.document.write(embed);
        // x.document.close();
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
        dispatch(successAddAging(res.data));
      })
      .catch((error) => {
        dispatch(errorAddAging());
      });
  };
};
