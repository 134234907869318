import API from "../../../../global/api";
import { groupBy, isEmpty, get } from "lodash";

//LEVEL 1
export const requestLevel1GetData = () => {
  return {
    type: "REQUEST_LEVEL1_GET_DATA",
  };
};
export const successLevel1GetData = (data) => {
  return {
    type: "SUCCESS_LEVEL1_GET_DATA",
    payload: data,
  };
};
export const errorLevel1GetData = () => {
  return {
    type: "ERROR_LEVEL1_GET_DATA",
  };
};

export const getLevel1Data = () => {
  return (dispatch) => {
    dispatch(requestLevel1GetData());
    API.get(`/levelOne/get_all_levelone`)
      .then((res) => {
        let getData = res.data.Name;
        dispatch(successLevel1GetData(getData));
      })
      .catch((error) => {
        dispatch(errorLevel1GetData());
      });
  };
};
// get level 2
export const getLevelOneName = (data) => {
  return (dispatch) => {
    dispatch(requestLevelOneData());
    API.get(`/levelTwo/get-by-levelOne/${data}`)
      .then((res) => {
        // dispatch(getLevel2Data());
        dispatch(successLevelOneData(res.data));
      })
      .catch((error) => {
        dispatch(errorAddLevelOne());
      });
  };
};

export const requestLevelOneData = () => {
  return {
    type: "REQUEST_LEVEL1_DATA",
  };
};
export const successLevelOneData = (data) => {
  return {
    type: "SUCCESS_LEVEL1_DATA",
    payload: data,
  };
};
export const errorAddLevelOne = () => {
  return {
    type: "ERROR_LEVEL1_DATA",
  };
};
// endget level 2
// start level 3
export const getLevelTwoName = (data) => {
  return (dispatch) => {
    dispatch(requestLevelTwoData());

    API.get(`/levelThree/get-by-levelTwo/${data}`)
      .then((res) => {
        // dispatch(getLevel2Data());
        dispatch(successLevelTwoData(res.data));
      })
      .catch((error) => {
        dispatch(errorAddLevelTwo());
      });
  };
};

export const requestLevelTwoData = () => {
  return {
    type: "REQUEST_LEVEL2_DATA",
  };
};
export const successLevelTwoData = (data) => {
  return {
    type: "SUCCESS_LEVEL2_DATA",
    payload: data,
  };
};
export const errorAddLevelTwo = () => {
  return {
    type: "ERROR_LEVEL2_DATA",
  };
};
// end LEVEL 3
// start level 4
export const getLevelFourName = (data) => {
  return (dispatch) => {
    dispatch(requestLevelFourData());

    API.get(`levelFour/get-by-levelThree/${data}`)
      .then((res) => {
        // dispatch(getLevel2Data());
        dispatch(successLevelFourData(res.data));
      })
      .catch((error) => {
        dispatch(errorAddLevelFour());
      });
  };
};

export const requestLevelFourData = () => {
  return {
    type: "REQUEST_LEVEL4_DATA",
  };
};
export const successLevelFourData = (data) => {
  return {
    type: "SUCCESS_LEVEL4_DATA",
    payload: data,
  };
};
export const errorAddLevelFour = () => {
  return {
    type: "ERROR_LEVEL4_DATA",
  };
};
// // end LEVEL 4
export const requestAddLevel2 = () => {
  return {
    type: "REQUEST_ADD_LEVEL2",
  };
};
export const successAddLevel2 = (data) => {
  return {
    type: "SUCCESS_ADD_LEVEL2",
    payload: data,
  };
};
export const errorAddLevel2 = () => {
  return {
    type: "ERROR_ADD_LEVEL2",
  };
};

export const AddLevel2Name = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddLevel2());
    API.post(`/levelTwo/add-levelTwo`, data)
      .then((res) => {
        dispatch(getLevel2Data());
        dispatch(successAddLevel2(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddLevel2());
        handleDangerVisible();
      });
  };
};

export const requestLevel2GetData = () => {
  return {
    type: "REQUEST_LEVEL2_GET_DATA",
  };
};
export const successLevel2GetData = (data) => {
  return {
    type: "SUCCESS_LEVEL2_GET_DATA",
    payload: data,
  };
};
export const errorLevel2GetData = () => {
  return {
    type: "ERROR_LEVEL2_GET_DATA",
  };
};

export const getLevel2Data = () => {
  return (dispatch) => {
    dispatch(requestLevel2GetData());
    API.get(`levelTwo/get-all-levelTwo`)
      .then((res) => {
        let getData = res.data;
        dispatch(successLevel2GetData(getData));
      })
      .catch((error) => {
        dispatch(errorLevel2GetData());
      });
  };
};
//LEVEL 3
export const requestAddLevel3 = () => {
  return {
    type: "REQUEST_ADD_LEVEL5",
  };
};
export const successAddLevel3 = (data) => {
  return {
    type: "SUCCESS_ADD_LEVEL3",
    payload: data,
  };
};
export const errorAddLevel3 = () => {
  return {
    type: "ERROR_ADD_LEVEL3",
  };
};

export const AddLevel3Name = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddLevel3());
    API.post(`/levelThree/add-levelThree`, data)
      .then((res) => {
        dispatch(getLevel3Data());
        dispatch(successAddLevel3(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddLevel3());
        handleDangerVisible();
      });
  };
};

export const requestLevel3GetData = () => {
  return {
    type: "REQUEST_LEVEL3_GET_DATA",
  };
};
export const successLevel3GetData = (data) => {
  return {
    type: "SUCCESS_LEVEL3_GET_DATA",
    payload: data,
  };
};
export const errorLevel3GetData = () => {
  return {
    type: "ERROR_LEVEL2_GET_DATA",
  };
};

export const getLevel3Data = () => {
  return (dispatch) => {
    dispatch(requestLevel3GetData());
    API.get(`levelThree/get-all-data`)
      .then((res) => {
        let getData = res.data;
        dispatch(successLevel3GetData(getData));
      })
      .catch((error) => {
        dispatch(errorLevel3GetData());
      });
  };
};
//LEVEL 4
export const requestAddLevel4 = () => {
  return {
    type: "REQUEST_ADD_LEVEL4",
  };
};
export const successAddLevel4 = (data) => {
  return {
    type: "SUCCESS_ADD_LEVEL4",
    payload: data,
  };
};
export const errorAddLevel4 = () => {
  return {
    type: "ERROR_ADD_LEVEL4",
  };
};

export const AddLevel4Name = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddLevel4());
    API.post(`/levelFour/add-levelFour`, data)
      .then((res) => {
        dispatch(getLevel4Data());
        dispatch(successAddLevel4(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddLevel4());
        handleDangerVisible();
      });
  };
};

export const requestLevel4GetData = () => {
  return {
    type: "REQUEST_LEVEL4_GET_DATA",
  };
};
export const successLevel4GetData = (data) => {
  return {
    type: "SUCCESS_LEVEL4_GET_DATA",
    payload: data,
  };
};
export const errorLevel4GetData = () => {
  return {
    type: "ERROR_LEVEL4_GET_DATA",
  };
};

export const getLevel4Data = () => {
  return (dispatch) => {
    dispatch(requestLevel4GetData());
    API.get(`/levelFour/get-all-data`)
      .then((res) => {
        let getData = res.data;
        dispatch(successLevel4GetData(getData));
      })
      .catch((error) => {
        dispatch(errorLevel4GetData());
      });
  };
};
//LEVEL 5
export const requestAddLevel5 = () => {
  return {
    type: "REQUEST_ADD_LEVEL5",
  };
};
export const successAddLevel5 = (data) => {
  return {
    type: "SUCCESS_ADD_LEVEL5",
    payload: data,
  };
};
export const errorAddLevel5 = () => {
  return {
    type: "ERROR_ADD_LEVEL5",
  };
};

export const AddLevel5Name = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddLevel5());
    API.post(`/levelFive/add-levelFive`, data)
      .then((res) => {
        dispatch(getLevel5Data());
        dispatch(successAddLevel5(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddLevel5());
        handleDangerVisible();
      });
  };
};

export const requestLevel5GetData = () => {
  return {
    type: "REQUEST_LEVEL5_GET_DATA",
  };
};
export const successLevel5GetData = (data) => {
  return {
    type: "SUCCESS_LEVEL5_GET_DATA",
    payload: data,
  };
};
export const errorLevel5GetData = () => {
  return {
    type: "ERROR_LEVEL2_GET_DATA",
  };
};

export const getLevel5Data = () => {
  return (dispatch) => {
    dispatch(requestLevel5GetData());
    API.get(`/levelFive/`)
      .then((res) => {
        let getData = res.data;
        dispatch(successLevel5GetData(getData));
      })
      .catch((error) => {
        dispatch(errorLevel5GetData());
      });
  };
};

//getcreditdebit
export const requestcreditdebit = () => {
  return {
    type: "REQUEST_CREDITDEBIT_GET_DATA",
  };
};
export const successLcreditdebit = (data) => {
  return {
    type: "SUCCESS_CREDITDEBIT_GET_DATA",
    payload: data,
  };
};
export const errorLcreditdebit = () => {
  return {
    type: "ERROR_CREDITDEBIT_GET_DATA",
  };
};

export const getcreditdebit = () => {
  return (dispatch) => {
    dispatch(requestcreditdebit());
    API.get(`levelFive//creditdebit/`)
      .then((res) => {
        let getData = res.data;
        dispatch(successLcreditdebit(getData));
      })
      .catch((error) => {
        dispatch(errorLcreditdebit());
      });
  };
};

export const DeleteRequestLevel5 = () => {
  return {
    type: "DELETE_REQUEST_LEVEL5",
  };
};
export const DeleteSuccessLevel5 = (level_id) => {
  return {
    type: "DELETE_SUCCESS_LEVEL5",
    level_id: level_id,
  };
};
export const DeleteErrorLevel5 = () => {
  return {
    type: "DELETE_ERROR_LEVEL5",
  };
};

export const deleteLevel5 = (levelFiveId,handleDangerVisible) => {
  return (dispatch) => {
    dispatch(DeleteRequestLevel5());
    API.delete(`/levelFive/delete-data/${levelFiveId}`)
      .then((res) => {
        dispatch(getLevel5Data());
        dispatch(DeleteSuccessLevel5(res));
      })
      .catch((error) => {
        dispatch(DeleteErrorLevel5());
      handleDangerVisible("This Account Has Some Tranctions Init, That is Why You Can't Delete This Account.")}
    );
  };
};
///
export const RequestLevel5Report = () => {
  return {
    type: "REQUEST_LEVEL5_REPORT",
  };
};
export const SuccessLevel5Report = (data) => {
  return {
    type: "SUCCESS_LEVEL5_REPORT",
    payload: data,
  };
};
export const ErrorLevel5Report = () => {
  return {
    type: "ERROR_LEVEL5_REPORT",
  };
};
export const Level5ReportPDF = (array) => {
  return (dispatch) => {
    //  dispatch(RequestLevel5Report());
    //  console.log(array,"beforecall");
    const groupedLevel3 = groupBy(!isEmpty(array) && array, function (x) {
        return x.levelfiveData.level_one.name;
      }),
      keys = !isEmpty(groupedLevel3) && Object.keys(groupedLevel3);
    keys.map((y, index) => {
      let x = get(groupedLevel3, `${y}`);
      console.log(y, index, "daaaaaaaaaaaaaaaaaaa");
      console.log(x, "dataaaaaaa");
    });
  };
};
export const updateLevel5 = (levelFiveId, data) => {
  return (dispatch) => {
    // dispatch(UpdateRequestLevel5());
    API.put(`/levelFive/update_level_five/${levelFiveId}`, data)
      .then((res) => {
        dispatch(getLevel5Data());
        // dispatch(DeleteSuccessLevel5(levelFiveId))
      })
      .catch((error) => dispatch(DeleteErrorLevel5()));
  };
};

export const requestUploadLevel2File = () => {
  return {
    type: "REQUEST_UPLOAD_LEVEL2_FILE",
  };
};
export const successUploadLevel2File = (data) => {
  return {
    type: "SUCCESS_UPLOAD_LEVEL2_FILE",
    payload: data,
  };
};
export const errorUploadLevel2File = () => {
  return {
    type: "ERROR_UPLOAD_LEVEL2_FILE",
  };
};

export const uploadLevel2File = (data, handleVisible, handleDangerVisible) => {
  const formData = new FormData();
  formData.append("file", get(data, "file"));
  formData.append("levelOneId", `${data.levelOneId}`);
  return (dispatch) => {
    dispatch(requestUploadLevel2File());
    API.post(`/levelTwo/upload-lvlTwo/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        let getData = res.data;
        dispatch(successUploadLevel2File(getData));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorUploadLevel2File());
        handleDangerVisible();
      });
  };
};

export const requestUploadLevel3File = () => {
  return {
    type: "REQUEST_UPLOAD_LEVEL3_FILE",
  };
};
export const successUploadLevel3File = (data) => {
  return {
    type: "SUCCESS_UPLOAD_LEVEL3_FILE",
    payload: data,
  };
};
export const errorUploadLevel3File = () => {
  return {
    type: "ERROR_UPLOAD_LEVEL3_FILE",
  };
};

export const uploadLevel3File = (data, handleVisible, handleDangerVisible) => {
  const formData = new FormData();
  formData.append("file", get(data, "file"));
  formData.append("levelOneId", `${data.levelOneId}`);
  formData.append("levelTwoId", `${data.levelTwoId}`);
  return (dispatch) => {
    dispatch(requestUploadLevel3File());
    API.post(`/levelThree/upload-lvlThree`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        let getData = res.data;
        dispatch(successUploadLevel3File(getData));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorUploadLevel3File());
        handleDangerVisible();
      });
  };
};

export const requestUploadLevel4File = () => {
  return {
    type: "REQUEST_UPLOAD_LEVEL4_FILE",
  };
};
export const successUploadLevel4File = (data) => {
  return {
    type: "SUCCESS_UPLOAD_LEVEL4_FILE",
    payload: data,
  };
};
export const errorUploadLevel4File = () => {
  return {
    type: "ERROR_UPLOAD_LEVEL4_FILE",
  };
};

export const uploadLevel4File = (data, handleVisible, handleDangerVisible) => {
  const formData = new FormData();
  formData.append("file", get(data, "file"));
  formData.append("levelOneId", `${data.levelOneId}`);
  formData.append("levelTwoId", `${data.levelTwoId}`);
  formData.append("levelThreeId", `${data.levelThreeId}`);
  return (dispatch) => {
    dispatch(requestUploadLevel4File());
    API.post(`/levelFour/upload-lvlFour`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        let getData = res.data;
        dispatch(successUploadLevel4File(getData));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorUploadLevel4File());
        handleDangerVisible();
      });
  };
};

export const requestUploadLevel5File = () => {
  return {
    type: "REQUEST_UPLOAD_LEVEL5_FILE",
  };
};
export const successUploadLevel5File = (data) => {
  return {
    type: "SUCCESS_UPLOAD_LEVEL5_FILE",
    payload: data,
  };
};
export const errorUploadLevel5File = () => {
  return {
    type: "ERROR_UPLOAD_LEVEL5_FILE",
  };
};

export const uploadLevel5File = (data, handleVisible, handleDangerVisible) => {
  const formData = new FormData();
  formData.append("file", get(data, "file"));
  formData.append("levelOneId", `${data.levelOneId}`);
  formData.append("levelTwoId", `${data.levelTwoId}`);
  formData.append("levelThreeId", `${data.levelThreeId}`);
  formData.append("levelFourId", `${data.levelFourId}`);
  return (dispatch) => {
    dispatch(requestUploadLevel5File());
    API.post(`/levelFive/upload-lvlFive/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        let getData = res.data;
        dispatch(successUploadLevel5File(getData));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorUploadLevel5File());
        handleDangerVisible();
      });
  };
};
