import React, { useEffect} from "react";
import { Container, Button, Tabs, Tab } from "react-bootstrap";
import "./add-levels.css";
import {
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  getLevel4Data,
  getLevel5Data,
  AddLevel5Name,
  getcreditdebit,
  deleteLevel5,
  updateLevel5,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../../Images/logo.png";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { sortBy, toUpper } from "lodash";
import Swal from "sweetalert";
const Level5Tap = (props) => {
  const level5Data = sortBy(props.level5List, [
    "props.level5List",
    "levelfiveData.allLevelKey",
  ]);
  let data=[{id:0,label:"Debit"},{id:1,label:"Credit"}]
  // const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = React.useState(false);

  // const handleVisible = () => {
  //   setAlertVisible(true);
  //   setTimeout(() => {
  //     setAlertVisible(false);
  //   }, 9000);
  //   Swal({
  //     title: "Success",
  //     text: "IGP Added Successfully",
  //     icon: "success",
  //     button: "Ok",
  //   });
  // };

  const handleDangerVisible = (error) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: error,
      icon: "error",
      button: "Ok",
    });
  };
  /*const [state, setState] = React.useState({
        columns: [
            { title: 'Level 1 Name', field: 'levelfiveData.level_one.name', render: rowData => rowData.levelfiveData.level_one.name + " /" + rowData.levelfiveData.level_one.key },
            { title: 'Level 2 Name', field: 'levelfiveData.level_two.levelTwoName', render: rowData => rowData.levelfiveData.level_two.levelTwoName + " /" + rowData.levelfiveData.level_one.key + "-" + rowData.levelfiveData.level_two.levelTwoKey },
            { title: 'Level 3 Name', field: 'levelfiveData.level_three.levelThreeName', render: rowData => rowData.levelfiveData.level_three.levelThreeName + " /" + rowData.levelfiveData.level_one.key + "-" + rowData.levelfiveData.level_two.levelTwoKey + "-" + rowData.levelfiveData.level_three.levelThreeKey },
            { title: 'Level 4 Name', field: 'levelfiveData.level_four.levelFourName', render: rowData => rowData.levelfiveData.level_four.levelFourName + " /" + rowData.levelfiveData.level_one.key + "-" + rowData.levelfiveData.level_two.levelTwoKey + "-" + rowData.levelfiveData.level_three.levelThreeKey + "-" + rowData.levelfiveData.level_four.levelFourKey },
            { title: 'Level 5 Code', field: 'levelfiveData.allLevelKey', editable: 'never' },
            { title: 'Level 5 Name', field: 'levelfiveData.levelFiveName' },
            { title: 'Amount Type', field: 'levelfiveData.debit_credit.accountName' },
            { title: 'Income', field: 'levelfiveData.levelBalance' },
        ],
    });*/

  useEffect(() => {
    // props.getcreditdebit();
    // props.getLevel1Data();
    // props.getLevel2Data();
    // props.getLevel3Data();
    // props.getLevel4Data();
    props.getLevel5Data();
  }, []);

  return (
    <>
      {props.isFetchinglevel5List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div className="sendDiv">
              <Link to="/user/level5">
                {" "}
                <Button className="sendButton">Back</Button>
              </Link>
            </div>

            <MaterialTable
              title="Level 4 Details"
              // columns={state.columns}
              columns={[
                {
                  title: "Level 1 Name",
                  editable: "never",
                  field: "levelfiveData.level_one.name",
                  render: (rowData) =>
                    rowData.levelfiveData.level_one.name +
                    " /" +
                    rowData.levelfiveData.level_one.key,
                },
                // { title: 'Level 2 Name', editable: 'never',field: 'levelfiveData.level_two.levelTwoName', render: rowData => rowData.levelfiveData.level_two.levelTwoName + " /" + rowData.levelfiveData.level_one.key + "-" + rowData.levelfiveData.level_two.levelTwoKey },
                {
                  title: "Level 2 Name",
                  editable: "never",
                  field: "levelfiveData.level_three.levelThreeName",
                  render: (rowData) =>
                    rowData.levelfiveData.level_three.levelThreeName +
                    " /" +
                    rowData.levelfiveData.level_one.key +
                    "-" +
                    rowData.levelfiveData.level_two.levelTwoKey +
                    "-" +
                    rowData.levelfiveData.level_three.levelThreeKey,
                },
                {
                  title: "Level 3 Name",
                  editable: "never",
                  field: "levelfiveData.level_four.levelFourName",
                  render: (rowData) =>
                    rowData.levelfiveData.level_four.levelFourName +
                    " /" +
                    rowData.levelfiveData.level_one.key +
                    "-" +
                    rowData.levelfiveData.level_two.levelTwoKey +
                    "-" +
                    rowData.levelfiveData.level_three.levelThreeKey +
                    "-" +
                    rowData.levelfiveData.level_four.levelFourKey,
                },
                {
                  title: "Level 4 Code",
                  editable: "never",
                  field: "levelfiveData.allLevelKey",
                  editable: "never",
                },
                { title: "Level 4 Name", field: "levelfiveData.levelFiveName" },
                { 
                  title: "Opening Balance",
                  field: "levelfiveData.open_blc",
                },
                {
                  title: "Opening Amount Type",
                  // editable: "never",
                  field: "levelfiveData.open_blc_type2",
                  // render: (rowData) =>rowData.levelfiveData.open_blc_type==1?"Credit":"Debit",
         
                editComponent: ({ value, onChange }) => (
                  // onChange(e.target.value)
                  // console.log(e.target.value,"eeeeeeeeeeeeeee")
                  <select onChange={(e) => onChange(e.target.value) 
                  } style={{padding:'10px'}}>
                    <option selected value={value}>
                      {value}
                    </option>                  
                    {data.map(
                      (item) =>
                      // console.log(item,"iiiiiiiiiiiiiiiiiiii")
                        item.label !== value && (
                          console.log(item,value,"tttttttt"),
                          <option key={item.id} value={item.label}>
                            {item.label}
                          </option>
                        )
                    )}
                  </select>
                )
              },
                {
                  title: "Closing Balance",
                  editable: "never",
                  field: "levelfiveData.levelBalance",
                  render: (rowData) =>
                    rowData.levelfiveData.levelBalance.toLocaleString(),
                },
                {
                  title: "Closing Amount Type",
                  editable: "never",
                  field: "levelfiveData.debit_credit.accountName",
                  // lookup: { 1: 'Debit', 2: 'Credit' } ,
              
                },
              ]}
              data={level5Data}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                pageSize: 200,
                pageSizeOptions: [30, 50, 100],
                emptyRowsWhenPaging: false,

                exportPdf: (columns, data) => {
                  const doc = new jsPDF();

                  const columnTitles = [
                    {
                      title: "Level 1 Name",
                      field: "levelfiveData.level_one.name",
                      render: (rowData) =>
                        rowData.levelfiveData.level_one.name +
                        " /" +
                        rowData.levelfiveData.level_one.key,
                    },
                    {
                      title: "Level 2 Name",
                      field: "levelfiveData.level_two.levelTwoName",
                      render: (rowData) =>
                        rowData.levelfiveData.level_two.levelTwoName +
                        " /" +
                        rowData.levelfiveData.level_one.key +
                        "-" +
                        rowData.levelfiveData.level_two.levelTwoKey,
                    },
                    {
                      title: "Level 3 Name",
                      field: "levelfiveData.level_three.levelThreeName",
                      render: (rowData) =>
                        rowData.levelfiveData.level_three.levelThreeName +
                        " /" +
                        rowData.levelfiveData.level_one.key +
                        "-" +
                        rowData.levelfiveData.level_two.levelTwoKey +
                        "-" +
                        rowData.levelfiveData.level_three.levelThreeKey,
                    },
                    {
                      title: "Level 4 Name",
                      field: "levelfiveData.level_four.levelFourName",
                      render: (rowData) =>
                        rowData.levelfiveData.level_four.levelFourName +
                        " /" +
                        rowData.levelfiveData.level_one.key +
                        "-" +
                        rowData.levelfiveData.level_two.levelTwoKey +
                        "-" +
                        rowData.levelfiveData.level_three.levelThreeKey +
                        "-" +
                        rowData.levelfiveData.level_four.levelFourKey,
                    },
                    {
                      title: "Level 5 Code",
                      field: "levelfiveData.allLevelKey",
                      editable: "never",
                    },
                    {
                      title: "Level 5 Name",
                      field: "levelfiveData.levelFiveName",
                    },
                    {
                      title: "Amount Type",
                      field: "levelfiveData.debit_credit.accountName",
                    },
                    { title: "Income", field: "levelfiveData.levelBalance" },
                  ].map((columnDef) => columnDef.title);

                  // const pdfData = data.map(rowData =>
                  //   state.columns.map(columnDef => (console.log(rowData.levelTwoData[columnDef.field], "eeeee"), columnDef.field == 'voucherDate' ? moment(rowData[columnDef.field]).format("YYYY-MM-DD") : rowData[columnDef.field])),
                  // );

                  const pdfData = data.map((elt) => [
                    elt.levelfiveData.level_one.name,
                    elt.levelfiveData.level_two.levelTwoName,
                    elt.levelfiveData.level_three.levelThreeName,
                    elt.levelfiveData.level_four.levelFourName,
                    elt.levelfiveData.allLevelKey,
                    elt.levelfiveData.levelFiveName,
                    elt.levelfiveData.debit_credit.accountName,
                    elt.levelfiveData.levelBalance,
                  ]);
                  let content = {
                    startY: 50,
                    startX: 5,
                    head: [columnTitles],
                    body: pdfData,
                  };
                  doc.addImage(logo, "PNG", 10, 11, 30, 30);
                  doc.text(
                    `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`,
                    100,
                    20,
                    0,
                    20
                  );
                  doc.text("Level3 Report", 30, 45, 0, 20);
                  doc.autoTable(content);

                  doc.save(`data.pdf`);
                },
                maxBodyHeight: "600px",
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    console.log(oldData, "rerrrr");
                    setTimeout(() => {
                      resolve();
                      props.deleteLevel5(oldData.levelfiveData.levelFiveId,handleDangerVisible);
                    }, 600);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      console.log(newData, "nf");
                      resolve();
                      props.updateLevel5(oldData.levelfiveData.levelFiveId, {
                        levelFiveName: toUpper(
                          newData.levelfiveData.levelFiveName
                        ),
                        open_blc_type:newData.levelfiveData.open_blc_type2=="Credit"?1:2,
                        open_blc_type2:newData.levelfiveData.open_blc_type2,
                        open_blc:newData.levelfiveData.open_blc,
                        // debitCreditId:newData.levelfiveData.debit_credit.accountName
                      });
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    getLevel2Data: () => dispatch(getLevel2Data()),
    getLevel3Data: () => dispatch(getLevel3Data()),
    getLevel4Data: () => dispatch(getLevel4Data()),
    getLevel5Data: () => dispatch(getLevel5Data()),
    AddLevel5Name: (data) => dispatch(AddLevel5Name(data)),
    getcreditdebit: () => dispatch(getcreditdebit()),
    deleteLevel5: (levelFiveId,handleDangerVisible) => dispatch(deleteLevel5(levelFiveId,handleDangerVisible)),
    updateLevel5: (levelFiveId, data) =>
      dispatch(updateLevel5(levelFiveId, data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level1List: state.accountsLevelReducer.level1List,
  level2List: state.accountsLevelReducer.level2List,
  level3List: state.accountsLevelReducer.level3List,
  level4List: state.accountsLevelReducer.level4List,
  level5List: state.accountsLevelReducer.level5List,
  D_CList: state.accountsLevelReducer.D_CList,
  creditDebitValue: state.accountsLevelReducer.creditDebitValue,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
  isAddingLevel5: state.accountsLevelReducer.isAddingLevel5,
  isNotAddLevel5: state.accountsLevelReducer.isNotAddLevel5,
});

export default connect(mapStateToProps, mapDispatchToProps)(Level5Tap);
