import react from "react";
import "jspdf-autotable";
import jsPDF from "jspdf";
import logo from "../../src/Images/logo.png";
import moment from "moment";
import { sumBy,filter,isEmpty } from "lodash";

export const appendNonSignificantZeros = (id) => {
  if (Number(id) < 10 && Number(id) > 0) {
    return (id = `000${id}`);
  }
  if (Number(id) < 100 && Number(id) >= 10) {
    return (id = `00${id}`);
  }
  if (Number(id) < 1000 && Number(id) <= 100) {
    return (id = `0${id}`);
  }
  if (Number(id) >= 1000) {
    return id;
  }
  return id;
};

export const startFinancialDate = "2022-07-01";
export const endFinancialDate = "2023-06-30";
export function debugBase64(base64URL) {
  var win = window.open();
  if (win)
    win.document.write(
      '<iframe src="' + base64URL + "_blank",
      '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
}
export function getVoucherName(id) {
  if (id == "1") return "Cash Payment Voucher";
  else if (id == "2") return "Cash Reciept Voucher";
  else if (id == "3") return "Bank Payment Voucher";
  else if (id == "4") return "Bank Reciept Voucher";
  else return "Journal Voucher";
}
export const previewVoucher = (data, status) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(11);
  const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
  const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
  const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
  const title = "Accounts Ledger";

  doc.addImage(logo, "PNG", 660, 10, 150, 60);
  doc.setFontSize(20);
  doc.setFont("bold");
  doc.text(companyName, 290, 30, 0, 20);
  doc.setFontSize(14);
  // doc.setFont("TimesNewRoman", "Normal");
  doc.text(companyAddress, 220, 50, 0, 20);
  doc.text(companyNtn, 320, 70, 0, 20);
  doc.setFontSize(20);
  doc.text(title, 290, 180);

  let yMargin = 190;
  doc.setFontSize(14);


  doc.setFont("times", "Normal");
  // doc.text(companyName, 200, 60, 0, 20);
  doc.setFontSize(18);
  doc.text(getVoucherName(data.voucherTypeId), 10, 100, 0, 20);
  doc.setFontSize(14);
  doc.text(`Voucher#${data.voucherManualId}`, 10, 120, 0, 20);
  doc.text(
    `Voucher Status: ${
      status == "Completed"
        ? data.status == "Approve"
          ? "Approved"
          : "Pending"
        : "Pending"
    }`,
    10,
    140,
    0,
    20
  );
  doc.text(
    `Date:${
      status == "Pending"
        ? data.data[0].voucherDate
        : data.voucher_data[0].voucherDate
    }`,
    10,
    160,
    0,
    20
  );
  const headers = [
    [
      "ACC Code",
      "Bill#",
      "Description",
      "Debit",
      "Credit",
      "Tracking No",
      "Destination",
      "Packages",
      "Weight",
      "Weight Charges",
      "Rate First Kg",
      "Rate Add Kg",
      "Flat Rate",
      "Service",
    ],
  ];
  const headersSigned = [
    ["Prepared By:", "Approved By:", "Audited By:", "Recieved By:"],
  ];
  const tableData =
    status == "Pending"
      ? data.data.map((elt) => [
          elt.accountTittle,
          elt.billNo,
          elt.voucherDescription,
          elt.accountType !== "1" ? elt.voucherAmount : "",
          elt.accountType == "1" ? elt.voucherAmount : "",
          elt.trackingNo,
          elt.destination,
          elt.packages,
          elt.weight,
          elt.weightCharges,
          elt.rateFirstKg,
          elt.rateAddKg,
          elt.rateFlat,
          elt.service,
        ])
      : data.voucher_data.map((elt) => [
          elt.accountTittle,
          elt.billNo,
          elt.voucherDescription,
          elt.accountType !== "1" ? elt.voucherAmount : "",
          elt.accountType == "1" ? elt.voucherAmount : "",
          elt.trackingNo,
          elt.destination,
          elt.packages,
          elt.weight,
          elt.weightCharges,
          elt.rateFirstKg,
          elt.rateAddKg,
          elt.rateFlat,
          elt.service,
        ]);
  const tableDataAcc =
    status == "Pending"
      ? [
          [
            data.cashAccTittle,
            mergeNames(data.data, "billNo"),
            mergeNames(data.data, "voucherDescription"),
            data.voucherTypeId == "2" || data.voucherTypeId == "4"
              ? sumBy(data.data, "voucherAmount")
              : "",
            data.voucherTypeId == "1" || data.voucherTypeId == "3"
              ? sumBy(data.data, "voucherAmount")
              : "",
          ],
        ]
      : [
          [
            data.cashAccTittle,
            mergeNames(data.voucher_data, "billNo"),
            mergeNames(data.voucher_data, "voucherDescription"),
            data.voucherTypeId == "2" || data.voucherTypeId == "4"
              ? sumBy(data.voucher_data, "voucherAmount")
              : "",
            data.voucherTypeId == "1" || data.voucherTypeId == "3"
              ? sumBy(data.voucher_data, "voucherAmount")
              : "",
          ],
        ];

  let content = {
    startY: 200,
    startX: 5,
    head: headers,
    // tableWidth: "wrap",
    body: tableData,
    theme: "grid",
    styles: { fontSize: 7 },
    margin: {
      left: 1,
      right: 1,
      // size:2
    },
    columnStyles: {
      0: { cellWidth: 120 },
      1: { cellWidth: 60 },
      2: { cellWidth: 160 },
      3: { cellWidth: 60 },
      4: { cellWidth: 60 },
    },
    headStyles: {
      fillColor: "#2980ba",
    },
  };

  doc.autoTable(content);
  // let contentAcc = {
  //     startY: doc.lastAutoTable.finalY + 10,
  //     startX: 5,
  //     head: [],
  //     body: tableDataAcc,
  //     theme: 'grid',
  //     columnStyles: {
  //         0: { cellWidth: 160 },
  //         1: { cellWidth: 60 },
  //         2: { cellWidth: 180 },
  //         3: { cellWidth: 60 },
  //         4: { cellWidth: 60 },

  //     },
  //     headStyles: {
  //         fillColor: '#2980ba'
  //     },
  // };
  // doc.autoTable(contentAcc);
  let contentSign = {
    startY: doc.lastAutoTable.finalY + 10,
    startX: 5,
    head: headersSigned,
    0: { cellWidth: 130 },
    1: { cellWidth: 130 },
    2: { cellWidth: 130 },
    3: { cellWidth: 130 },
    body: [
      [
        { content: "", rowSpan: 5 },
        { content: "", rowSpan: 5 },
        { content: "", rowSpan: 5 },
        { content: "", rowSpan: 5 },
      ],
    ],
    theme: "grid",
    headStyles: {
      fillColor: "#2980ba",
    },

    bodyStyles: {
      minCellHeight: 300,
    },
  };
  doc.autoTable(contentSign);
  // doc.text(`Posted at ${moment(new Date()).format("YYYY-MM-DD")} and audited by`, 50, doc.lastAutoTable.finalY + 15, 0, 20);
  doc.setProperties({ title: "test" });
  //ct doc.output('dataurlnewwindow')
  //oc.save(`Vochouer Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)
  // doc.save(`${data.voucherManualId}`);
  doc.setProperties({
    title: `${data.voucherManualId} .pdf`,
  });
  var blob = doc.output("blob");
  window.open(URL.createObjectURL(blob));
  // var string = doc.output('datauristring','dddd');
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
  // var x = window.open('Demand Report');
  // x.document.open('Demand Report');
  // x.document.write(embed);
  // x.document.close();
};
export const previewInvoice = (data, status) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(11);
  const title = "Invnoice";

  doc.addImage(logo, "PNG", 200, 15, 150, 60);
  let yMargin = 190;
  doc.setFontSize(14);

  const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;

  // doc.setFont("times", "bolditalic");
  doc.text('Al Khail Center, Suit# 111,Kashmir Road Sialkot - Pakistan', 90, 100, 0, 20);
  doc.text('Contact # +923016318323, 052 3242483/Email:info@massexpress.com.pk', 62, 120, 0, 20);
  const filterAray=filter(status == "Pending"?data.data:data.voucher_data,{invoiceShow:true})
  console.log(filterAray,"aaaaaaaaaaaaaaaaaaa")
  doc.setFont("times", "bolditalic");
   doc.text(`${isEmpty(filterAray)?"--":filterAray[0].accountTittle}`, 20, 160, 0, 20);
   doc.text(`Tracking # ${isEmpty(filterAray)?"--":filterAray[0].trackingNo}`, 20, 180, 0, 20);
   doc.text(`Service ${isEmpty(filterAray)?"--":filterAray[0].service}`, 20, 200, 0, 20);

   doc.text(`Date of Invoice: ${isEmpty(filterAray)?"--":filterAray[0].voucherDate}`, 350, 160, 0, 20);
   doc.text(`Invoice # ${isEmpty(filterAray)?"--":filterAray[0].voc_manual_number}`, 350, 180, 0, 20);
   doc.text(`No of CTNS ${isEmpty(filterAray)?"--":filterAray[0].packages}`, 350, 200, 0, 20);
  
   const headers = [
        [
          // "ACC Code",
          "Bill#",
          "Description",
          // "Debit",
          // "Credit",
          "Tracking No",
          "Destination",
          "Packages",
          "Weight",
          "Amount",
          // "Weight Charges",
          // "Rate First Kg",
          // "Rate Add Kg",
          // "Flat Rate",
          // "Service",
        ],
      ];
      const tableData =
   isEmpty(filterAray)?[]:filterAray.map((elt) => [
          // elt.accountTittle,
          elt.billNo,
          elt.voucherDescription,
          // elt.accountType !== "1" ? elt.voucherAmount : "",
          // elt.accountType == "1" ? elt.voucherAmount : "",
          elt.trackingNo,
          elt.destination,
          elt.packages,
          elt.weight,
          elt.voucherAmount
          // elt.weightCharges,
          // elt.rateFirstKg,
          // elt.rateAddKg,
          // elt.rateFlat,
          // elt.service,
        ])
          let content = {
    startY: 220,
    startX: 5,
    head: headers,
    // tableWidth: "wrap",
    body: tableData,
    theme: "grid",
    styles: { fontSize: 7 },
    margin: {
      left: 15,
      right: 15,
      // size:2
    },
    // columnStyles: {
    //   0: { cellWidth: 120 },
    //   1: { cellWidth: 60 },
    //   2: { cellWidth: 160 },
    //   3: { cellWidth: 60 },
    //   4: { cellWidth: 60 },
    // },
    headStyles: {
      fillColor: "#2980ba",
    },
  };

  doc.autoTable(content);
  console.log(tableData,"tttttttttttttttttttttt")
  doc.setFont("times", "normal");
  doc.text(`Total Amount:  ${sumBy(tableData,"6")}`, 400, doc.lastAutoTable.finalY + 30, 0, 20);
  doc.text(`Note Any discrepancy in invoice must be notified with in 03 days of receipt of this invoice `, 20, doc.lastAutoTable.finalY + 50, 0, 20);
  doc.text(`and payment must be settled with immediate effect`, 20, doc.lastAutoTable.finalY + 70, 0, 20);
  
  doc.setProperties({ title: "test" });

  doc.setProperties({
    title: `${data.voucherManualId} .pdf`,
  });
  var blob = doc.output("blob");
  window.open(URL.createObjectURL(blob));

};
// export const previewInvoice = (data, status) => {
//   const unit = "pt";
//   const size = "A4"; // Use A1, A2, A3 or A4
//   const orientation = "portrait"; // portrait or landscape

//   const marginLeft = 40;
//   const doc = new jsPDF(orientation, unit, size);
//   doc.setFontSize(11);
//   const title = "Invnoice";

//   doc.addImage(logo, "PNG", 200, 15, 100, 100);
//   // doc.text(title, marginLeft, 175);
//   //

//   let yMargin = 190;
//   doc.setFontSize(14);

//   const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;

//   doc.setFont("times", "bolditalic");
//   // doc.text(companyName, 200, 60, 0, 20);
//   // doc.setFontSize(18);
//   // doc.text(getVoucherName(data.voucherTypeId), 200, 80, 0, 20);
//   // doc.setFontSize(14);
//   // doc.text(`Voucher#${data.voucherManualId}`, 225, 95, 0, 20);
//   // doc.text(
//   //   `Voucher Status: ${
//   //     status == "Completed"
//   //       ? data.status == "Approve"
//   //         ? "Approved"
//   //         : "Pending"
//   //       : "Pending"
//   //   }`,
//   //   200,
//   //   115,
//   //   0,
//   //   20
//   // );
//   // doc.text(
//   //   `Date:${
//   //     status == "Pending"
//   //       ? data.data[0].voucherDate
//   //       : data.voucher_data[0].voucherDate
//   //   }`,
//   //   225,
//   //   135,
//   //   0,
//   //   20
//   // );
//   const headers = [
//     [
//       "ACC Code",
//       "Bill#",
//       "Description",
//       "Debit",
//       "Credit",
//       "Tracking No",
//       "Destination",
//       "Packages",
//       "Weight",
//       "Weight Charges",
//       "Rate First Kg",
//       "Rate Add Kg",
//       "Flat Rate",
//       "Service",
//     ],
//   ];
//   const headersSigned = [
//     ["Prepared By:", "Approved By:", "Audited By:", "Recieved By:"],
//   ];
//   const filterAray=filter(status == "Pending"?data.data:data.voucher_data,{invoiceShow:true})
//   const tableData =
//     // status == "Pending"
//     //   ? 
//    isEmpty(filterAray)?[]:filterAray.map((elt) => [
//           elt.accountTittle,
//           elt.billNo,
//           elt.voucherDescription,
//           elt.accountType !== "1" ? elt.voucherAmount : "",
//           elt.accountType == "1" ? elt.voucherAmount : "",
//           elt.trackingNo,
//           elt.destination,
//           elt.packages,
//           elt.weight,
//           elt.weightCharges,
//           elt.rateFirstKg,
//           elt.rateAddKg,
//           elt.rateFlat,
//           elt.service,
//         ])
//       // : data.voucher_data.map((elt) => [
//       //     elt.accountTittle,
//       //     elt.billNo,
//       //     elt.voucherDescription,
//       //     elt.accountType !== "1" ? elt.voucherAmount : "",
//       //     elt.accountType == "1" ? elt.voucherAmount : "",
//       //     elt.trackingNo,
//       //     elt.destination,
//       //     elt.packages,
//       //     elt.weight,
//       //     elt.weightCharges,
//       //     elt.rateFirstKg,
//       //     elt.rateAddKg,
//       //     elt.rateFlat,
//       //     elt.service,
//       //   ]);


//   let content = {
//     startY: 150,
//     startX: 5,
//     head: headers,
//     // tableWidth: "wrap",
//     body: tableData,
//     theme: "grid",
//     styles: { fontSize: 7 },
//     margin: {
//       left: 1,
//       right: 1,
//       // size:2
//     },
//     columnStyles: {
//       0: { cellWidth: 120 },
//       1: { cellWidth: 60 },
//       2: { cellWidth: 160 },
//       3: { cellWidth: 60 },
//       4: { cellWidth: 60 },
//     },
//     headStyles: {
//       fillColor: "#2980ba",
//     },
//   };

//   // doc.autoTable(content);
  
//   let contentSign = {
//     startY: doc.lastAutoTable.finalY + 10,
//     startX: 5,
//     head: headersSigned,
//     0: { cellWidth: 130 },
//     1: { cellWidth: 130 },
//     2: { cellWidth: 130 },
//     3: { cellWidth: 130 },
//     body: [
//       [
//         { content: "", rowSpan: 5 },
//         { content: "", rowSpan: 5 },
//         { content: "", rowSpan: 5 },
//         { content: "", rowSpan: 5 },
//       ],
//     ],
//     theme: "grid",
//     headStyles: {
//       fillColor: "#2980ba",
//     },

//     bodyStyles: {
//       minCellHeight: 300,
//     },
//   };
//   // doc.autoTable(contentSign);

//   // doc.text(`Posted at ${moment(new Date()).format("YYYY-MM-DD")} and audited by`, 50, doc.lastAutoTable.finalY + 15, 0, 20);
//   doc.setProperties({ title: "test" });
//   //ct doc.output('dataurlnewwindow')
//   //oc.save(`Vochouer Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)
//   // doc.save(`${data.voucherManualId}`);
//   doc.setProperties({
//     title: `${data.voucherManualId} .pdf`,
//   });
//   var blob = doc.output("blob");
//   window.open(URL.createObjectURL(blob));
//   // var string = doc.output('datauristring','dddd');
//   // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
//   // var x = window.open('Demand Report');
//   // x.document.open('Demand Report');
//   // x.document.write(embed);
//   // x.document.close();
// };

export function mergeNames(array, obj) {
  let output = [];

  for (let item of array) {
    output.push(`[${item[obj]}]`);
  }
  return output.toString();
}
